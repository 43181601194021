import { matchPath } from "react-router";

/**
 * Finds route from `routes` by pathname
 * @param {Array[Object]} routes - route descriptions with `name` and `path` keys (from `/src/route.js`)
 * @param {String} pathname - current url pathname
 * @returns {String|Undefined} - name of the found route or `undefined`
 */
export default function detectRoute(routes, pathname) {
  for (let route of routes) {
    if (matchPath(pathname, { path: route.path })) {
      return route.name;
    }
  }
}
