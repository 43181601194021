import React, { Children } from "react";
import { Trans } from "@lingui/macro";
import classNames from "../../../functions/classNames";
import "./ProductCardLabel.css";

/**
 * Part of props for ProductCardLabel
 * @typedef {Object} ProductCardLabelProps
 * @prop {String} theme
 * @prop {Array[React::Element]} children
 */

/**
 * Variants for props of `ProductCardLabel`
 * @type {Object}
 * @prop {ProductCardLabelProps} hit - bestseller label
 * @prop {ProductCardLabelProps} unavailable - not available label
 */
const labelContent = {
  hit: {
    theme: null,
    children: <Trans>Bestseller</Trans>,
  },
  unavailable: {
    theme: "gray",
    children: <Trans>Temporarily unavailable</Trans>,
  },
  mts: {
    theme: "mts",
    children: <Trans>MTS</Trans>,
  },
  popular: {
    theme: "popular",
    children: <Trans>popular</Trans>,
  },
};

/**
 * Resolves which type of content and theme will be applied to `ProductCardLabel`
 * @param {Object} flags
 * @param {Boolean} flags.hit
 * @param {Boolean} flags.available
 * @param {Boolean} flags.popular
 * @returns {ProductCardLabelProps} - theme and content justified by `flags` param
 */
export function getLabelContent({ hit, available, mts, popular }) {
  if (!available) {
    return labelContent.unavailable;
  }

  if (mts) {
    return labelContent.mts;
  }

  if (hit) {
    return labelContent.hit;
  }

  if (popular) {
    return labelContent.popular;
  }

  return {};
}

/**
 * Label on `ProductCard`
 * @param {Object} $
 * @param {String} $.className - additional CSS class
 * @param {String} $.theme - components theme (`"accent"`, `"gray"`)
 * @param {Array[React::Element]} $.children - strings to display in the component
 */
export default function ProductCardLabel({ className, theme, children }) {
  return Children.count(children) ? (
    <span
      className={classNames("ProductCardLabel", theme && `ProductCardLabel--${theme}`, className)}
    >
      {children}
    </span>
  ) : null;
}
