import { PRODUCT_CARD_COVER_WIDTH } from "../../constants/image/sizes";
import { MAX_MOBILE_SCREEN } from "../../constants/screen/devices";
import getRealSize from "../../functions/screen/getRealSize";

/**
 * Calculates product card width depending from window width
 * !IMPORTANT: this is rough calculation - originally for lowering image quality
 * @return {Number}
 */
export default function getProductCardMaxWidth(windowWidth) {
  return windowWidth > MAX_MOBILE_SCREEN ? PRODUCT_CARD_COVER_WIDTH : getRealSize(windowWidth);
}
