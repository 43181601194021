import React from "react";
import uuid from "react-uuid";
import { Plural, Trans } from "@lingui/macro";
import { Icon } from "../Icon";
import classNames from "../../functions/classNames";
import "./Rating.css";

/**
 * `RatingStar` star sizes in dependance of the theme
 * @type {Object}
 */

/**
 * Stars
 * @param {Object} $
 * @param {String?} $.size - size of the component (`"small"`, `"medium"`)
 * @param {Number} $.grade - amount of stars to display as given
 */
export function RatingStars({ starsType, grade, iconSize, onClick = () => {}, className = "" }) {
  const filledStarPercentage = grade >= 5.0 ? (grade / 5) * 100 : (grade / 5) * 55;

  const starIconNames = {
    small: {
      filled: "star/white",
      empty: "star/whiteBorder",
    },
    medium: {
      filled: "star/white",
      empty: "star/whiteBorder",
    },
    large: {
      filled: "star/accent",
      empty: "star/accentBorder",
    },
    gray: {
      filled: "star/accent",
      empty: "star/grey",
    },
    smallAccent: {
      filled: "star/accent",
      empty: "star/accentBorder",
    },
    default: {
      filled: "star/accent",
      empty: "star/accentBorder",
    },
  };

  const starIconName = starIconNames[starsType]?.filled || starIconNames.default.filled;
  const emptyStarIconName = starIconNames[starsType]?.empty || starIconNames.default.empty;

  const customIconSize = iconSize ? { height: iconSize, width: iconSize } : {};
  const filledStarIcon = <Icon name={starIconName} {...customIconSize} />;
  const emptyStarIcon = <Icon name={emptyStarIconName} {...customIconSize} />;

  const renderStarItem = (item, i) => (
    <div key={uuid()} className="Rating__item" onClick={() => onClick(i + 1)}>
      {item <= grade ? filledStarIcon : emptyStarIcon}
    </div>
  );

  const starItems = Array.from({ length: 5 }, (_, index) => index + 1);

  return (
    <div className={`Rating__stars ${className}`}>
      {starsType === "small" || starsType === "medium" || starsType === "smallAccent" ? (
        <div className="Rating__item">
          <div className="Rating__filled-star" style={{ width: `${filledStarPercentage}%` }}>
            {filledStarIcon}
          </div>
          <div className="Rating__empty-star">{emptyStarIcon}</div>
        </div>
      ) : (
        starItems.map(renderStarItem)
      )}
    </div>
  );
}

/**
 * Amount of reviews
 * @param {Object} $
 * @param {String?} $.size - size of the component (`"small"`, `"medium"`)
 * @param {Number?} $.count - amount of reviews
 * @param {String?} $.countType - type of `count` displaying way (`"short"` - only number in brackets)
 */
export const RatingCount = ({ count, countType, theme = "main", className }) => {
  const countClassName = theme && classNames(`Rating__count--${theme}`);
  const isLargeCount = countType === "large" && countType !== "short";

  // Function to determine the count translation
  const getCountTranslation = countValue => {
    const lastDigit = countValue % 10;
    const lastTwoDigits = countValue % 100;

    if (lastDigit === 1 && lastTwoDigits !== 11) {
      return <Trans>rating & review</Trans>;
    } else if ([2, 3, 4].includes(lastDigit) && ![12, 13, 14].includes(lastTwoDigits)) {
      return <Trans>ratings & review</Trans>;
    }
    return <Trans>ratings & reviews</Trans>;
  };

  let countDisplay;

  if (countType === "large" || isLargeCount) {
    countDisplay = (
      <div>
        <Icon
          className={classNames(
            "Rating__count_icon",
            theme === "main" && "",
            theme !== "main" && "icon-reviews-gray",
          )}
          name="reviews/main"
        />
        {count} {getCountTranslation(count)}
      </div>
    );
  } else if (countType === "medium") {
    countDisplay = (
      <div>
        {count} {getCountTranslation(count)}
      </div>
    );
  } else if (countType !== "medium") {
    countDisplay = `(${count})`;
  }

  return (
    <div className={classNames("Rating__count", countClassName, className)}>{countDisplay}</div>
  );
};

export const OverallRating = ({ overallCount }) => {
  if (!overallCount || !overallCount.avgRating) {
    return null;
  }
  const grade = Math.floor(overallCount.avgRating * 10) / 10;

  const maxCount = Math.max(
    overallCount.ratingsDistribution.five_star,
    overallCount.ratingsDistribution.four_star,
    overallCount.ratingsDistribution.three_star,
    overallCount.ratingsDistribution.two_star,
    overallCount.ratingsDistribution.one_star,
  );

  const RatingBar = ({ starsType, count }) => {
    const percentage = (count / overallCount.totalRatings) * 100;
    const textWidth = `${(maxCount.toString().length + 1) * 10}px`;

    return (
      <div className="OverallRating__bar">
        <div className="OverallRating__bar-text" style={{ width: textWidth }}>
          {count}
        </div>
        <div className="OverallRating__bar-background">
          <div className="OverallRating__bar-fill" style={{ width: `${percentage}%` }} />
        </div>
        <div className="OverallRating__bar-star">
          <RatingStars gradeType="short" grade={starsType + 0.99} starsType="smallAccent" />
          {starsType}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="OverallRating__title">
        <Trans>Overall rating</Trans>
      </div>
      <div className="OverallRating">
        <div className="OverallRating__main">
          <div className="Rating__stars_overall-container">
            <RatingStars
              className="Rating__stars_overall"
              gradeType="short"
              grade={overallCount.avgRating}
              starsType="small"
            />
            <span className="Rating__grade_overall">{grade}</span>
          </div>
          <div className="OverallRating__details">
            <span>
              <Plural
                value={overallCount.totalRatings}
                _0=""
                one="# rating"
                few="# ratings"
                many="# ratings"
                other="# ratings"
              />{" "}
            </span>
            <span>
              <Plural
                value={overallCount.totalReviews}
                _0=""
                one="# review"
                few="# reviews"
                many="# reviews"
                other="# reviews"
              />{" "}
            </span>
          </div>
        </div>
        <div className="OverallRating__bars">
          <RatingBar starsType={5} count={overallCount.ratingsDistribution.five_star} />
          <RatingBar starsType={4} count={overallCount.ratingsDistribution.four_star} />
          <RatingBar starsType={3} count={overallCount.ratingsDistribution.three_star} />
          <RatingBar starsType={2} count={overallCount.ratingsDistribution.two_star} />
          <RatingBar starsType={1} count={overallCount.ratingsDistribution.one_star} />
        </div>
      </div>
    </>
  );
};

/**
 * Rating (stars with amount of reviews)
 * @param {Object} $
 * @param {String} $.className - additional CSS class name
 * @param {String?} $.size - size of the component (`"small"`, `"medium"`)
 * @param {String?} $.theme - component theme (`"light-main"`, `"gray"`)
 * @param {String?} $.gradeType - type of grade displaying way (`"short"` - 1 symbol, , `"slash"` - number with maximum value divided by forward slash)
 * @param {String?} $.countType - type of `count` displaying way (`"short"` - only number in brackets, `"long"` - number with text in brackets)
 * @param {Number} $.grade - amount of stars (component will not be displayed if there are no grade)
 * @param {Number?} $.count - amount of reviews
 */
export default ({ theme, grade, gradeType, starsType, size, count, countType, className }) => {
  const gradeNum = Math.floor(Number(grade));
  if (!gradeNum) {
    return null;
  }

  let balancedGray = theme !== "light-main" ? Number(grade).toFixed(1) : Number(grade);
  if (gradeType === "short") {
    balancedGray = Math.floor(Number(balancedGray));
  }
  return (
    <div
      className={classNames(
        "Rating",
        size && `Rating--${size}`,
        theme && `Rating--${theme}`,
        className,
      )}
    >
      {starsType !== "large" ? (
        <div className="Rating__stars_container">
          <RatingStars size={size} grade={grade} starsType={starsType} />
          <span className="Rating__grade">{balancedGray}</span>
        </div>
      ) : (
        <>
          <RatingStars size={size} grade={grade} starsType={starsType} />
          <span className="Rating__grade">{balancedGray}</span>
        </>
      )}
      {count && starsType !== "large" && countType ? (
        <RatingCount size={size} count={count} countType={countType} theme={theme} />
      ) : null}
    </div>
  );
};
