import { t } from "@lingui/macro";
import { hasAudio } from "./hasAudio";
import hasTickets from "./hasTickets";

export const PRODUCT_CONTENT_SIZES = {
  sm: { headphones: { width: "11", height: "11" }, ticket: { width: "12", height: "12" } },
  l: { headphones: { width: "16", height: "15.4" }, ticket: { width: "16", height: "17.6" } },
};

/**
 * Translations for product content display string
 * @type {Object}
 */
const MESSAGES = {
  tickets: t`with tickets`,
  ticketsSingle: t`Tickets`,
  audioGuide: t`Audio tour`,
  audioGuideSingle: t`Audio tour`,
};

/**
 * Gets human-friendly ready-to-display info about product contents (audio guide/audio guide with tickets, etc.)
 * @param {Object} product - product description from server
 * @param {Object} i18n - `Lingui::I18n` object
 * @param {Object?} messagesUnsafe - messages with fields equal to used in `MESSAGES` const for overloading translations
 * @returns {Object} - `icons` field includes array of icons to display, `text` includes array of text to display
 */
export default function getProductContentDisplayInfo(product, i18n, messagesUnsafe = {}) {
  const messages = { ...MESSAGES, ...messagesUnsafe };

  /**
   * Content map of the block (icons and texts)
   * @type {Object}
   */
  const contents = { icons: [], text: [] };

  const isAudioTour = hasAudio(product);
  const isTickets = hasTickets(product);

  if (isAudioTour) {
    contents.icons.push({ name: "headphones" });
    contents.text.push(isTickets ? i18n._(messages.audioGuide) : i18n._(messages.audioGuideSingle));
  }
  if (isTickets) {
    contents.icons.push({ name: "ticket" });
    contents.text.push(isAudioTour ? i18n._(messages.tickets) : i18n._(messages.ticketsSingle));
  }

  return contents;
}
