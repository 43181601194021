/**
 * Max screen size of the device considered as mobile
 * In device independent pixels
 * @type {Number}
 */
export const MAX_MOBILE_SCREEN = 767;

/**
 * Max screen size of the device considered as tablet
 * In device independent pixels
 * @type {Number}
 */
export const MAX_TABLET_SCREEN = 991;
