import get from "lodash/get";

/**
 * Checks if `product` contains tickets
 * @param {Object} product - product description from the server
 * @returns {Boolean}
 */
export default function hasTickets(product) {
  // eslint-disable-next-line no-bitwise
  return !!(product.type & 2) || get(product?.tickets?.[0], "hasTiqets");
}
// hasTiqets: true;
// hasTour: true;
