import React from "react";
import { Trans } from "@lingui/macro";
import classNames from "../../../functions/classNames";
import "./withTip.css";

/**
 * Adds tipping text to `Price`
 * @param {React::Component} Price - modified component
 */
export default Price =>
  /**
   * Price with tipping text
   * @param {Object} $
   * @param {String} $.priceClassName - additional CSS class for `Price__price` element
   * @param {String} $.align - `justify-content` CSS prop for root block
   * @param {Number} $.exprice - old price, will be stroked
   * @param {Number} $.capitalize - first letter in element will be capitalized (bit mask, 1st bit - `Price__price`, 2nd - `Price__annotation`)
   * @param {Boolean} $.predicate - show "from" before the price
   * @param {Boolean} $.perTour - shows "per tour" insted of "per person"
   * @param {Boolean} $.inline - will be displayed in one line
   * @param {String} $.theme - `mobile` or default theme
   */
  ({
    priceClassName,
    className,
    theme,
    value,
    currencyCode,
    exprice,
    expriceBefore,
    perTour,
    align,
    inline,
    predicate,
    capitalize,
  }) => {
    const expriceBlock =
      exprice && exprice !== value ? (
        <span className="t-15 Price__exprice">
          {exprice && value !== exprice ? (
            <Price value={exprice} currencyCode={currencyCode} />
          ) : null}
        </span>
      ) : null;

    const isFree = value === 0;
    return (
      <span
        className={classNames(
          "Price Price--withTip",
          inline && "Price--inline",
          // eslint-disable-next-line no-bitwise
          capitalize & 1 && "Price--capitalized-price",
          // eslint-disable-next-line no-bitwise
          capitalize & 2 && "Price--capitalized-annotation",
          className,
        )}
        style={{ justifyContent: align }}
      >
        <span className={classNames("Price__price", priceClassName)}>
          {predicate ? <Trans>from</Trans> : null}{" "}
          {!isFree ? <Price value={value} currencyCode={currencyCode} /> : <Trans>Free</Trans>}
          {expriceBefore ? expriceBlock : null}
        </span>
        {!expriceBefore ? expriceBlock : null}
        {!(theme === "card" || theme === "mobile") && (
          <span className="Price__annotation">
            {!isFree && (!perTour ? <Trans>per person</Trans> : <Trans>per tour</Trans>)}
          </span>
        )}
      </span>
    );
  };
