import React from "react";
import classNames from "../../functions/classNames";
import Image from "../Image";
import "./CoverImage.css";

export default ({ src, className, alt, ...props }) => {
  // TODO: properly set responive layout
  
  return (
    <Image
      className={classNames("CoverImage", className)}
      src={src}
      loading="lazy"
      alt={alt}
      {...props}
    />
  );
};
