import { t } from "@lingui/macro";
import getProductContentDisplayInfo from "../../functions/product/getProductContentDisplayInfo";

/**
 * Translations to overload default `getProductContentDisplayInfo` dictionary
 * @type {Object}
 */
const MESSAGES = {
  tickets: t`Entry ticket`,
  ticketsSingle: t`Entry ticket`,
  audioGuide: t`Audio tour`,
  audioGuideSingle: t`Self-guided audio tour`,
};

/**
 * Gets tour contents display string for the `ProductCardLabel`
 * @param {Object} product - product description from the server
 * @param {Object} i18n - `Lingui::I18n` translator instance
 * @returns - label text describing tour contents
 */
export default function getProductCardContentsLabel(product, i18n) {
  return getProductContentDisplayInfo(product, i18n, MESSAGES).text.reverse().join(" + ");
}
