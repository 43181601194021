import React from "react";
import { Trans } from "@lingui/macro";
import classNames from "../../../functions/classNames";
import Rating from "../../Rating";
import "./ProductCardRating.css";

/**
 * Adapter for rating of the tour in card
 * @param {Object} $
 * @param {String} $.theme - components theme (`"horizontal"`)
 * @param {String} $.cardSize - size of the root component
 * @param {Number} $.rating - amount of stars with which product was rated
 * @param {Number} $.amount - amount of reviews
 */
export default function ProductCardRating({ cardSize, isNew, theme, rating, amount }) {
  const isHorizontal = theme === "horizontal";
  let ratingLabel = null;

  if (isNew) {
    ratingLabel = <Trans>New</Trans>;
  }

  ratingLabel = (
    <Rating
      gradeType={cardSize === "small" ? "short" : null}
      grade={rating}
      theme={isHorizontal ? "light-main" : "unfocused"}
      count={isHorizontal ? null : amount}
      countType="short"
      starsType="small"
    />
  );

  return (
    <span
      className={classNames(
        "ProductCardRating",
        !amount && "t-13 bold ProductCardRating--empty",
        theme && `ProductCardRating--${theme}`,
        cardSize && `ProductCardRating--${cardSize}`,
      )}
    >
      {ratingLabel}
    </span>
  );
}
