/**
 * Image in top bar block on main page width
 * @type {Number}
 */
export const TOP_BAR_COVER_WIDTH = 1920;

/**
 * Product card cover image default width
 * @type {Number}
 */
export const PRODUCT_CARD_COVER_WIDTH = 430;

/**
 * City card cover image default width
 * @type {Number}
 */
export const CITY_COVER_WIDTH = 325;

/**
 * Attraction card cover image default width
 * @type {Number}
 */
export const ATTRACTION_COVER_WIDTH = 360;

/**
 * Fullscreen gallery image default width
 * @type {Number}
 */
export const PRODUCT_GALLERY_COVER_WIDTH = 1560;
