import React from "react";
import { Trans } from "@lingui/react";
import "./Placeholder.css";

export default function ({ title, subtitle, children }) {
  // TODO: fix title
  return (
    <div className="Placeholder">
      {title && <h1 className="h1 m-32 m-64-t Subtitle">{title || <Trans>Ooops</Trans>}</h1>}
      {subtitle && <p>{subtitle}</p>}
      {children}
    </div>
  );
}
