/* eslint-disable no-nested-ternary */
/* eslint-disable no-bitwise */
import React, { useState } from "react";
import { withI18n } from "@lingui/react";
import { useMediaQuery } from "beautiful-react-hooks";
import { useLocation } from "react-router-dom";
import routes from "../../routes";
import detectRoute from "../../functions/route/detectRoute";
import { isSSR } from "../NoSSR";
import { PRODUCT_CARD_COVER_WIDTH } from "../../constants/image/sizes";
import classNames from "../../functions/classNames";
import getIndependentScreenSize from "../../functions/screen/getIndependentScreenSize";
import sendEvent, { convertProduct } from "../../functions/analytics";
import CoverImage from "../CoverImage";
import Favorite from "../Favorite";
import Price from "../Price";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import { Icon } from "../Icon";
import withTip from "../Price/withTip";
import ProductGalleryFader from "../ProductGallery/ProductGalleryFader";
import getProductCardMaxWidth from "./getProductCardMaxWidth";
import ProductCardLabel, { getLabelContent } from "./ProductCardLabel";
import ProductCardRating from "./ProductCardRating";
import "./ProductCard.css";
import getProductCardContentsLabel from "./getProductCardContentsLabel";
import { Trans } from "@lingui/macro";

/**
 * `Price` component modified with tipping text
 * @type {React::Component}
 */
const PriceTipped = withTip(Price);

// Formatting duration for correct display
function formatDuration(duration, lang) {
  const regex = /(\d*\.?\d*)\s*(hour|minute)s?/g;

  const formattedDuration = duration.replace(regex, (match, number, unit) => {
    const unitAbbreviation =
      lang === "ru" ? (unit.startsWith("hour") ? "ч" : "м") : unit.startsWith("hour") ? "h" : "m";

    return `${number}${unitAbbreviation}`;
  });

  return formattedDuration;
}

/**
 * Adapter for image gallery in `ProductCard`
 * @param {Object} $
 * @param {Array[ProductImage]} $.images - images to show in gallery
 * @param {Boolean} $.isSlideshow - trigger `ProductGalleryFader` to start fading slideshow
 */
function ProductCardImages({ isSlideshow, images = [], title }) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isMobile = !isSSR && useMediaQuery("(max-width: 767px)");
  const width =
    getProductCardMaxWidth(getIndependentScreenSize(), isMobile) || PRODUCT_CARD_COVER_WIDTH;

  return images.length > 1 ? (
    <ProductGalleryFader
      imageProps={{ width: Math.floor(width * 1.15) }} // 1.15 for image growing animation
      isStarted={isSlideshow}
      images={images}
    />
  ) : (
    <CoverImage
      width={width}
      src={typeof images[0] === "object" ? images[0].preview : ""}
      alt={title}
    />
  );
}

/**
 * Displays shrinked data about product
 * @param {Object} $
 * @param {Object} $.product - product description from backend
 * @param {String} $.className - additional CSS class name
 * @param {String} $.link - URL to open after click occurred
 * @param {String} $.theme - components theme (`"horizontal"`, `"light"`)
 * @param {Boolean} $.withFavorite - if button for toggling product in "favorites" list shown
 * @param {Function} $onClicked - callback to be called an click
 * @param {Boolean} $.disableDefaultSendEvent - if default event "Product Clicked" is sent to analytics,
 * @param {String} $.rel - `rel` attribute for the link
 */
export default withI18n()(function ProductCard({
  product,
  link,
  theme,
  size,
  className,
  withFavorite = true,
  position,
  listId,
  i18n,
  onClicked,
  disableDefaultSendEvent = false,
  showLabels = true,
  showFavorite = true,
  rel,
}) {
  const location = useLocation();
  const isTestCityPage = Boolean(
    detectRoute(
      routes.filter(r => /cityTest/.test(r.name)),
      location.pathname,
    ),
  );

  const {
    images = [],
    title: originalTitle,
    name,
    preview,
    currencyCode,
    currency,
    price,
    rating,
    duration,
    exprice,
    city,
    type,
    tags = {},
    author = {},
    available = true,
    new: isNew,
  } = product;
  const lang = useCurrentLanguage();
  const unavailable = typeof available === "boolean" && !available;
  const title = originalTitle || name;
  const formattedDuration = duration ? formatDuration(duration, lang) : "";

  const pricesProps = {
    value: price,
    exprice,
    currencyCode: currencyCode || currency?.code,
    perTour: !(type & 2),
    inline: true,
  };

  /**
   * If mouse is above component now
   * @type {Boolean}
   */
  const [hovered, setHovered] = useState(false);
  const onClick = () => {
    if (onClicked) onClicked();
    if (!disableDefaultSendEvent)
      sendEvent("track", "Product Clicked", {
        ...convertProduct(product, { link, position, currency: true }),
        list: listId,
        Referrer: window.location.href,
      });
  };

  return (
    <a
      href={link}
      target="_blank"
      {...(rel ? { rel } : {})}
      className={classNames(
        "ProductCard",
        unavailable && "ProductCard--unavailable",
        theme && `ProductCard--${theme}`,
        size && `ProductCard--${size}`,
        className,
      )}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <span className="ProductCard__head">
        <ProductCardImages
          isSlideshow={hovered}
          images={[{ preview }, ...images]}
          title={originalTitle}
        />
        {showLabels && (
          <div className="ProductCard__labels-bestseller">
            <ProductCardLabel
              {...getLabelContent({
                ...(tags || {}),
                available: !unavailable,
                mts: author.nickname === "mts",
                hit: !isTestCityPage && tags.popular ? "hit" : null,
                popular: isTestCityPage && tags.popular,
              })}
            />
          </div>
        )}
        {showFavorite && withFavorite ? (
          <span
            className={classNames(
              "ProductCard__favourite",
              theme && `ProductCard__favourite--${theme}`,
            )}
          >
            <Favorite product={product} showText={false} theme="simple" />
          </span>
        ) : null}
      </span>
      <span className="ProductCard__content">
        <span className="ProductCard__top">
          {showLabels && (
            <div className="ProductCard__labels">
              <ProductCardLabel theme="accent">
                {getProductCardContentsLabel(product, i18n)}
              </ProductCardLabel>
            </div>
          )}
          {duration && (
            <div>
              <Icon name="timer" />
              <span className="ProductCard__duration">{formattedDuration}</span>
            </div>
          )}
          <span className="t-m ProductCard__title">
            {title === "" || !title ? <Trans>Audio Tour in, {city.name}</Trans> : title}
          </span>
        </span>
        <span className="ProductCard__bottom">
          <PriceTipped {...pricesProps} />
          <ProductCardRating cardSize={size} rating={rating} isNew={isNew} />
        </span>
      </span>
    </a>
  );
});
