import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "beautiful-react-hooks";
import get from "lodash/get";
import { Trans } from "@lingui/macro";
import sendEvent from "../../functions/analytics";
import classNames from "../../functions/classNames";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import { ADD_USER_FAVOURITE, REMOVE_USER_FAVOURITE, LOGIN_POPUP_OPEN } from "../../stores/types";
import { isSSR } from "../NoSSR";
import { Icon } from "../Icon";
import Button from "../Button";
import "./Favorite.css";

const ICON_STYLES = {
  simple: {
    active: "heart/white",
    disabled: "heart",
  },
  cardCentered: {
    active: "heart/red-filled",
    disabled: "heart",
  },
  touch: {
    active: "heart/red-filled",
    disabled: "heart",
  },
};

/**
 * Toggling as favorite, when clicked on its content
 * @param {Object} $
 * @param {String} $.className - additional CSS class
 * @param {Object} $.product - description of the product adding to favorites
 * @param {String} $.theme - theme of the button
 * @param {Boolean} $.showText - show status in text
 */
export default function Favorite({ className, product, theme, showText = true }) {
  const lang = useCurrentLanguage();
  const dispatch = useDispatch();
  const { user, isLogged } = useSelector(
    ({ user: { user: storeUser, isLogged: storeLogged } }) => ({
      user: storeUser,
      isLogged: storeLogged,
    }),
  );
  const favoriteIds = get(user, "favorite_products", []);
  const isFavorite = favoriteIds.indexOf(product.id) !== -1;
  const [wishFavorited, setWishFavorited] = useState(false);

  useEffect(() => {
    if (isLogged && wishFavorited) {
      dispatch({ type: ADD_USER_FAVOURITE, product, lang });
      setWishFavorited(false);
    }
  }, [isLogged, wishFavorited, dispatch, product.id]);

  // eslint-disable-next-line
  const isMobile = !isSSR && useMediaQuery("(max-width: 767px)");
  const iconStyle = ICON_STYLES[theme] || ICON_STYLES.simple;

  return (
    <Button
      hideTextOnMobile
      className={classNames(
        "Favorite",
        !showText && `Favorite--noText`,
        theme && `Favorite--${theme}`,
        className,
      )}
      theme={theme}
      iconPosition="before"
      icon={
        <Icon
          name={isFavorite ? iconStyle.active : iconStyle.disabled}
          width={iconStyle.width}
          height={iconStyle.height}
        />
      }
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();

        sendEvent("track", "Add To Whishlist Click", { id: product.id, name: product.title });

        if (!isLogged) {
          setWishFavorited(true);
          dispatch({ type: LOGIN_POPUP_OPEN });
          return;
        }

        dispatch({
          type: !isFavorite ? ADD_USER_FAVOURITE : REMOVE_USER_FAVOURITE,
          productId: product.id,
          product,
          lang,
        });
      }}
    >
      <Trans>Save</Trans>
    </Button>
  );
}
