import get from "lodash/get";

/**
 * Checks if `product` contains audio guide
 * @param {Object} product - product description from the server
 * @returns {Boolean}
 */
export function hasAudio(product) {
  return !!(
    get(product?.tickets?.[0], "hasTour") ||
    get(product, "tags.audioguide") ||
    get(product, "types.audioguide") ||
    product.type !== 2
  );
}
