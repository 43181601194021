import React, { useState, useEffect, useRef } from "react";
import classNames from "../../../functions/classNames";
import Image from "../../Image";
import "./ProductGalleryFader.css";

/**
 * Light slide show gallery component with fading effect
 * @param {Object} $
 * @param {Number} $.wait - amount of seconds for showing slide
 * @param {Array[ProductImage]} $.images - images in gallery
 * @param {Object} $.imageProps - props passing to `Image` component
 */
export default function ProductGalleryFader({ isStarted, images = [], wait = 3000, imageProps }) {
  /**
   * Current image index
   * @type {Number}
   */
  const [current, setCurrent] = useState(0);

  /**
   * Current timer ID
   * @type {Number|Null}
   */
  const [timerId, _setTimerId] = useState(-1);

  /**
   * Is hovered state
   * @type {Boolean}
   */
  const [isHovered, setIsHovered] = useState(false);

  /**
   * Storage for keeping current timer id
   * Mirrors `timerId` from `useState` hook
   * Needed for accessing `timerId` fresh value in `useEffect`
   */
  const timerIdStorage = useRef(timerId);
  const setTimerId = newId => {
    timerIdStorage.current = typeof newId === "function" ? newId() : newId;
    _setTimerId(newId);
  };

  /**
   * Change `current` to next slide
   * @type {Function}
   */
  const fadeToNext = () => setCurrent((current + 1) % images.length);

  /**
   * Toggles slide show
   * @type {Function}
   * @param {Boolean} isRequired - if slideshow needs to be started
   */
  const toggleFading = isRequired => {
    if (isRequired) {
      fadeToNext();
      setTimerId(null);
    } else {
      clearTimeout(timerId);
      setTimerId(() => -1);
      setCurrent(0);
    }
  };

  /**
   * Tracking activation of slideshow from parent component, sets hovered state
   */
  useEffect(() => {
    toggleFading(isStarted);
    if (isStarted) {
      setIsHovered(true);
    }
  }, [isStarted]);

  /**
   * Manages timeouts
   * Sets new timeout when `timer` value is `null`
   */
  useEffect(() => {
    if (timerIdStorage.current === null) {
      setTimerId(
        setTimeout(() => {
          fadeToNext();
          setTimerId(null);
        }, wait),
      );
    }
  }, [timerId]);

  /**
   * Resets timeout when component is unmounted
   */
  useEffect(() => () => clearTimeout(timerId), []);

  return (
    <div className="ProductGalleryFader" style={{ "--show-timeout": `${wait / 1000 + 1}s` }}>
      {images.map(({ preview }, i) => (
        <Image
          key={preview}
          className={classNames(
            "ProductGalleryFader__img",
            i === current && "ProductGalleryFader__img--current",
            i === current && isStarted && "ProductGalleryFader__img--animate-scaling",
          )}
          src={isStarted || isHovered || i === 0 ? preview : ""}
          imgProxyOptions={{ gravity: "sm" }}
          loading="lazy"
          {...imageProps}
        />
      ))}
    </div>
  );
}
